import React from 'react'
import {
  P,
  Header,
  PrivacyOL,
  PrivacyUL,
  PrivacyLink,
  PrivacyContainer,
  MailtoLink
} from './DisclaimerStyles'

import { useGetCountry } from 'hooks'
import H1 from 'ui/bend/typography/H1'

const PrivacyText = ({ marginTop = 0 }) => {
  const country = useGetCountry()
  const url = country?.toLowerCase() === 'ca' ? '/terms?l=ca' : '/terms'

  return (
    <PrivacyContainer>
      <H1 style={{ fontSize: '30px' }} margin={`${marginTop} 0 20px 0`}>
        ALO MOVES PRIVACY POLICY
      </H1>
      <H1 style={{ fontSize: '30px' }} margin='0 0 40px 0'>
        LAST UPDATED: FEBRUARY 12, 2025
      </H1>
      <Header color='black' pt='0 !important'>
        <span className='item-number'>1.</span> Scope of Privacy Policy
      </Header>
      <P>
        This Privacy Policy describes how Alo, LLC d/b/a Alo Moves
        (collectively, “Alo Moves”, “we”, “our”, or “us”) collects, uses, and
        shares information about you (“you”, “your”, or “user”) when you use our
        website with a homepage located at “alomoves.com”, any applications or
        digital platforms we may provide (including mobile applications),
        together with all products and services we may offer from time to time
        via our website and/or related applications, or otherwise through your
        interactions with us (our website, applications, platforms, products,
        and services, collectively, the “Services”). This Privacy Policy also
        describes your and our legal rights and responsibilities with respect to
        information which identifies you or which could be used to identify you
        such as your name and contact details, or your user account information,
        which we call “personal information” in the text below. Alo Moves takes
        very seriously its responsibility to protect the safety and security of
        your personal information that we process and is committed to respecting
        your privacy rights
      </P>
      <Header color='black'>
        <span className='item-number'>2.</span>
        Applicability of Alo Moves' Privacy Policy
      </Header>
      <P>
        This Privacy Policy, together with our{' '}
        <PrivacyLink href={url} target='_blank'>
          Terms of Use
        </PrivacyLink>{' '}
        governs your access to and use of our Services. We will refer to access,
        use, transmission, and exchanges of information, availability or
        utilization of features, and functions or activities, simply as “use” in
        the material below.
      </P>
      <P>
        This Privacy Policy applies to personal information that we collect from
        or about you through the methods described below (see "Information We
        Collect" and "How We Use Your Information") to provide the Services. In
        this Privacy Policy, "personal information" means any information that
        relates to an identified or reasonably identifiable natural person, or
        as otherwise - 2 - defined under applicable law. This Privacy Policy
        applies regardless of the means used to access or provide the Services.
      </P>
      <P>
        This Privacy Policy does not apply to websites that link to Alo Moves’
        website or websites that may be accessed through the Services. We may
        provide links to other websites that contain information that we believe
        may be useful to you or that you will find valuable. If you access such
        a third-party website through a link we provide, you will be subject to
        the privacy policy of the new site. Alo Moves is not responsible for the
        contents of any linked webpages referenced from our website. If you have
        any questions regarding the privacy, security, or content of those
        sites, you should contact the appropriate contact(s) at those websites
        directly.
      </P>

      <Header color='black'>
        <span className='item-number'>3.</span>
        Acceptance of Privacy Policy
      </Header>
      <P>
        By using Alo Moves’ Services, including our Website, you agree that you
        have read and understand this Privacy Policy. If you do not agree to the
        terms of this Privacy Policy, please do not use Alo Moves’ Services.{' '}
        <b>
          Your continued use of Alo Moves’ Services following the posting of
          changes to this Privacy Policy will mean that you accept those
          changes.
        </b>
      </P>
      <Header color='black'>
        <span className='item-number'>4.</span>
        Information We Collect
      </Header>
      <P>
        We, and the vendors that perform services on our behalf, may collect,
        receive, and develop several different categories of personal
        information about you depending on the nature of your interactions with
        the Services. For example, we collect the following categories of
        personal information:
      </P>
      <PrivacyOL>
        <P as='li'>
          <b>Identifiers/Contact information:</b> We may collect your name,
          postal address, zip or postal code, telephone number, email address,
          social media handles, or other information that may be used to contact
          you. If you create an account, we also collect your username and
          password.
        </P>
        <P as='li'>
          <b>Purchase and Preference Information:</b>
          We may collect your order and class history, such as what classes,
          offerings, or products you have purchased or browsed through our
          Services. We may also collect your product, shopping, and class
          preferences.
        </P>
        <P as='li'>
          <b>Payment Information:</b> We may collect billing information, such
          as your account number, credit card number, expiration date,
          verification number, and billing address if you make a purchase.
        </P>
        <P as='li'>
          <b>Internet and Network Activity Information:</b> We may collect your
          Internet Protocol ("IP") address when you access our Services. We also
          use tracking tools like cookies and pixels to passively collect
          information about how you interact with our websites and online
          content. This information may include device identifiers, the type of
          browser or operating system you use, the dates and times you accessed
          the Services, what areas of our Services you visit and for how long,
          what products or content you view and click on, what site you came
          from to get to us and what site you go to when you leave us, and your
          interactions with our digital ads and content on other sites. We may
          also embed technologies (which may include, for example, tags, and
          pixels) in the emails and other communications we or our vendors send
          to you to help us understand how you interact with those messages,
          such as whether and when you opened a message or clicked on any
          content within the message. We may engage or otherwise allow other
          parties to also collect information on our Services in these ways.
        </P>
        <P as='li'>
          <b>Location Information:</b> We may passively collect information
          about your geolocation when you access our Services. We also collect
          information about your coarse location using your IP address.
        </P>
        <P as='li'>
          <b>Demographic Information:</b> We may collect demographic
          information, such as your age, date of birth, or gender.
        </P>
        <P as='li'>
          <b>Employment-related Information:</b> If you apply for a job, we may
          collect information about your work experience and previous
          employment, your education history, your resume, the URL to your
          personal or professional websites, your workplace skill sets and
          certifications, whether you need a visa to work in the United States,
          and salary expectations. Additional information about our privacy
          practices can be found in our Job Applicant Privacy Policy available{' '}
          <PrivacyLink
            href={
              'https://cdn.shopify.com/s/files/1/2185/2813/files/CCPA_Workforce_Member_Privacy_Policy_-_Alo_and_Bella_Canvas_-_June_2023_-_CA_ONLY.pdf?v=1687812344'
            }
            target='_blank'
          >
            HERE
          </PrivacyLink>
          .
        </P>
        <P as='li'>
          <b>Visual/Audio Information:</b> For example, we may record audio
          during customer service calls. We may also collect audio or visual
          information you post to our sites, like photos or videos.
        </P>
        <P as='li'>
          <b>Sensitive Information:</b> We may collect information that may be
          considered sensitive personal information under certain state or local
          laws. For example, we may collect information relating to your health,
          wellness, and/or physical activities. We may also collect the username
          and password for any account you create with us.
        </P>
        <P as='li'>
          <b>Information you Submit or Post:</b> We may collect information you
          post to our sites such as information about your fitness goals,
          fitness history, workout preferences, and associated photos or videos.
          We may also collect feedback and any other information you provide
          when you contact us or sign up for our services.
        </P>
        <P as='li'>
          <b>Inferences:</b> Using the other pieces of personal information
          collected about you, we draw inferences about you, reflecting what we
          believe to be your preferences, characteristics, predispositions, and
          attitudes.
        </P>
      </PrivacyOL>

      <Header color='black'>
        <span className='item-number'>5.</span>
        Sources of Personal Information{' '}
      </Header>
      <P>
        We and the vendors that perform services on our behalf may collect the
        above categories of personal information from various sources depending
        on the nature of your interactions with the Services. For example, we
        collect personal information from the following categories of sources:
      </P>
      <PrivacyUL>
        <P as='li'>
          <b>Directly from you:</b> We collect information directly from you.
          For example, we collect information when you use our Services, attend
          our classes, call us on the phone, create an online account, sign up
          to receive our emails, participate in a giveaway sweepstakes, contest,
          promotion or survey, communicate with us via third-party social media
          sites such as Facebook or Instagram, reserve an Alo yoga class,
          request customer support, apply for a job or otherwise communicate
          with us.
        </P>
        <P as='li'>
          <b>From third-party service providers:</b> We may collect information
          about you from service providers we hire to work on our behalf such
          vendors that maintain our website, process payments, and send
          promotional emails on our behalf.
        </P>
        <P as='li'>
          <b>
            From other third parties, including our marketing and business
            partners, and social media platforms:
          </b>{' '}
          We may collect or receive personal information from our business and
          marketing partners and social media platforms that give us information
          about you that they have collected either directly or indirectly from
          you. We may also receive information from a job network or social
          network if you choose to import your information from those platforms
          when applying for a job with us. We may also receive personal
          information about you from your friends or contacts when, for example,
          someone purchases a gift card and requests that we provide it
          electronically, schedules an Alo yoga class, or purchases and sends
          you products or class information. If you provide us with the personal
          information of another individual, you must have the consent of that
          individual to do so
        </P>
        <P as='li'>
          <b>We combine information about you:</b> We combine personal
          information collected offline, such as information collected about you
          when you participate in our community events or visit our retail
          stores and yoga studios, with information collected online. We combine
          personal information that we have collected across our subsidiaries'
          websites. We also combine personal information collected across
          devices, such as computers and mobile devices. These actions allow us
          to understand your consumer preferences more holistically. We also
          combine your information with other consumers’ information to
          understand preferences and trends within your peer group over time.
        </P>
        <P as='li'>
          <b>From your devices:</b> We use technical tracking tools that work
          behind the scenes, like browser cookies and pixels, to automatically
          collect information from and about you and your devices. We collect
          this information when you interact with our websites, our ads, and our
          emails that we or our vendors send to you. We collect this information
          about users over time and across devices.
        </P>
        <P as='li'>
          <b>From people you know:</b> We have referral programs that allow
          users to refer the Platform to their friends. We collect information
          provided to us from those users to reach out to you about the
          Services.
        </P>
      </PrivacyUL>

      <Header color='black'>
        <span className='item-number'>6.</span>
        How We Use Your Personal Information{' '}
      </Header>
      <P>
        We may use your personal information to provide products and services
        and to support our business functions. For example, we may use this
        information for the following business and commercial purposes:
      </P>
      <P>
        <b>Provide, Improve, and Develop the Alo Moves Platform.</b>
      </P>
      <PrivacyUL>
        <P as='li'>
          Provide the Services to you, including certain personalized services
        </P>
        <P as='li'>
          Providing your membership benefits, including by providing the classes
          you request
        </P>
        <P as='li'>
          Process and fulfill your order, including by, in some instances,
          shipping products to you, providing your membership or others you
          designate, and sending emails to you, or others you designate, to
          confirm your order status and shipment and to process merchandise
          returns
        </P>
        <P as='li'>Provide customer service</P>
        <P as='li'>
          Operate, protect, improve, and optimize the Services and the user
          experience, such as by performing analytics and conducting research
        </P>
        <P as='li'>
          Operate, protect, improve, and optimize the Services and the user
          experience, such as by performing analytics and conducting research
        </P>
        <P as='li'>
          Communicate with you and send you information by email, postal mail,
          telephone, text message, or other means about our products, services,
          contests, support messages, updates, security alerts, and account
          notifications
        </P>
        <P as='li'>
          Communicate with other persons using contact information you provide,
          such as when you designate another person as the recipient of a gift
          purchase
        </P>
        <P as='li'>
          Make your experience easier, more personalized, more enjoyable and
          more efficient
        </P>
        <P as='li'>
          Address problems with and improve our products, brands, services and
          technologies, as well as to develop new products and services
        </P>
        <P as='li'>Allow you to use our online technologies</P>
        <P as='li'>
          Provide consistent, personalized services, including to personalize
          our advertising, marketing communications, shopping experiences and
          promotional offers
        </P>
        <P as='li'>
          Organize local community events, promotions and experiences
        </P>
        <P as='li'>
          Facilitate your ability to share information to social media
        </P>
        <P as='li'>Administer and fulfill our contests and other promotions</P>
        <P as='li'>Help us learn more about your preferences</P>
        <P as='li'>
          To carry out, from time to time, surveys indicating your preferences
        </P>
        <P as='li'>Fulfill a contract we have with you</P>
        <P as='li'>
          Operate, protect, improve, and optimize the Services, and personalize
          and customize your experience (such as automatically entering your
          saved address information)
        </P>
        <P as='li'>
          Personalize the Services using your search and booking history, your
          profile information and preferences, and other content you submit to
          the Services
        </P>
      </PrivacyUL>
      <P>
        We process this information given our legitimate interest in improving
        the Services and our users' experience with it, and where it is
        necessary for the adequate performance of the contract with you.
      </P>
      <P>
        <b>Protect the Integrity of our Services.</b>
      </P>
      <PrivacyUL>
        <P as='li'>
          Prevent, detect, mitigate and investigate fraud, security breaches and
          activities that are or potentially may be prohibited or illegal
        </P>
        <P as='li'>Conduct security investigations and risk assessments</P>
        <P as='li'>Verify or authenticate information</P>
        <P as='li'>
          As we determine, in our sole discretion, to be required or appropriate
          to protect the rights, property, safety and security of Alo Moves and
          our employees, customers and others
        </P>
        <P as='li'>
          As we determine, in our sole discretion, to be required or appropriate
          under applicable law, to respond to requests from government
          authorities and to comply with legal processes, investigations,
          regulatory or governmental enquiries or for other legal or regulatory
          purposes
        </P>
        <P as='li'>Comply with our legal obligations</P>
        <P as='li'>
          Resolve any disputes with any of our users or shoppers and enforce our
          agreements with third parties
        </P>
        <P as='li'>Enforce our Terms of Use or other policies</P>
      </PrivacyUL>

      <P>
        We use your personal information in connection with automated decision
        making via our thirdparty payment provider(s) to protect our business
        from fraud during the checkout process and they may use customers'
        personal information to block certain transactions that appear to be
        fraudulent through automated decision-making.
      </P>
      <P>
        We process this information given our legitimate interest in protecting
        the Services, to measure the adequate performance of our contract with
        you, and to comply with applicable laws.
      </P>
      <P>
        <b>
          Provide, Personalize, Measure, and Improve our Advertising and
          Marketing.
        </b>
      </P>
      <PrivacyUL>
        <P as='li'>
          Send you promotional messages we believe may be of interest to you,
          marketing, advertising, and other information that may be of interest
          to you based on your preferences (including information about Alo
          Moves or partner campaigns and services) and social media advertising
          through social media platforms such as Facebook or Google.
        </P>
        <P as='li'>Personalize, measure, and improve our advertising.</P>
        <P as='li'>
          Administer referral programs, rewards, surveys, sweepstakes, contests,
          or other promotional activities or events sponsored or managed by Alo
          Moves or its third-party partners.
        </P>
        <P as='li'>
          Conduct profiling on your characteristics and preferences (based on
          the information you provide to us, your interactions with the
          Services, information obtained from third parties, and your search and
          booking history) to send you promotional messages, marketing,
          advertising and other information that we think may be of interest to
          you.
        </P>
      </PrivacyUL>
      <P>
        We will process your personal information for the purposes listed in
        this section given our legitimate interest in undertaking marketing
        activities to offer you products or services that may be of your
        interest. You can opt-out of receiving marketing communications from us
        by following the unsubscribe instructions included in our marketing
        communications or changing your notification settings within your Alo
        Moves Account.
      </P>
      <P>
        <b>Other Purposes.</b>
      </P>
      <PrivacyUL>
        <P as='li'>With your consent</P>
        <P as='li'>
          When it is necessary to protect your vital interests or that of
          another person
        </P>
        <P as='li'>
          When it is necessary for the performance of a task carried out in the
          public interest
        </P>
        <P as='li'>
          When it is in our legitimate interests. Legitimate interests are our
          business or commercial reasons for using your data, such as (i)
          keeping our records up to date; (ii) providing the Services to you;
          (iii) maintaining or administering the Services; (iv) performing
          business analyses or for other internal purposes to improve the
          quality of our business and the Services we offer; (v) prevention of
          fraud and financial crime to protect the public; (vi) communicating
          with you concerning programs or services consistent with our
          obligations to provide those services or otherwise; and (vii)
          participating in litigation, investigations, regulatory or
          governmental enquiries or for other legal or regulatory purposes
          involving our customers who use or have used our Services or other
          third parties. We will not unfairly place our legitimate interests
          above what is best for you.
        </P>
      </PrivacyUL>
      <Header color='black'>
        <span className='item-number'>7.</span>
        How We Share Your Personal Information
      </Header>
      <P>
        We share your personal information with the following categories of
        third parties:
      </P>
      <PrivacyUL>
        <P as='li'>
          <b>Brands.</b> We may share personal information with our Color Image
          Apparel, Inc. family of brands (including Alo, Alo Moves and
          Bella+Canvas).
        </P>
        <P as='li'>
          <b>Third Party Service Providers.</b> We share your information with
          third-party service providers who we work with or who manage our
          customer information and perform services on our behalf, such as
          hosting our e-commerce platform, fulfilling promotions, processing
          online payments, providing and maintaining website features, sending
          communications and products to our customers, and conducting surveys.
          Our third-party service providers are only authorized to collect, use,
          and disclose your information to the extent necessary to allow the
          third-party service providers to perform the services they provide to
          us.
        </P>
        <P as='li'>
          <b>Third Parties.</b> We may share your personal information when we
          partner with another company to offer or provide products, services,
          contests, or promotions to our customers.
        </P>
        <P as='li'>
          <b>Social Sharing.</b> We may share your personal information in
          connection with your social sharing activity, such as if you log into
          your customer account from your social media account. By connecting
          your customer account and your social media account, you authorize us
          to share information with your social media account provider, and you
          understand that the use of the information we share will be governed
          by the social media site's privacy policy.
        </P>
        <P as='li'>
          <b>Legal Requirements.</b> We may share your personal information if
          necessary or appropriate to comply with laws or regulations or in
          response to a valid subpoena, court order, legal processes, or
          government request, or to protect the operations, privacy, safety,
          property or rights of Alo, LLC and its affiliates or others, or if we
          determine, in our sole discretion, it is necessary to share such
          information in order to investigate, prevent, or take action regarding
          illegal activities, suspected fraud, situations involving potential
          threats to the physical safety of any person, or as otherwise required
          by law or to protect the public.
        </P>
        <P as='li'>
          <b>Business Transfer.</b> In the event of a sale or merger of Color
          Image Apparel, Inc., Alo, LLC, or any of our affiliated businesses,
          our customers' personal information and other information we have
          collected as described in this policy may be among the transferred
          business assets.
        </P>
        <P as='li'>
          <b>Other Disclosures.</b> We may share your personal information with
          our legal, regulatory, audit, and other professional advisors. Those
          companies may use your personal information to assist us in our
          operations consistent with our legitimate business interests. We also
          may share personal information about you as described at the point of
          collection, as described elsewhere in this Privacy Policy, or where
          otherwise permitted or required by law or where Alo Moves has a
          legitimate interest in doing so.
        </P>
      </PrivacyUL>
      <P>
        These third parties do not have any independent right to share your
        personal information. We will exercise reasonable measures to have the
        third parties to whom we disclose your personal information comply with
        applicable data protection laws.
      </P>
      <Header color='black'>
        <span className='item-number'>8.</span>
        How Long We Retain Your Personal Information
      </Header>
      <P>
        We will store your personal information for as long as is necessary to
        achieve the purposes for which it was collected, whether that is to
        provide services to you, for our own legitimate interests (described
        above), or so that we can comply with the law. We reserve the right to
        retain it to the full extent not prohibited by law. We may delete
        personal information in our discretion, so you should retain your own
        records, and not rely upon our storage of any personal information,
        content, or other data
      </P>
      <P>
        We will review the information we hold and when there no longer is a
        user, legal, or business need for us to hold it, we will either delete
        it securely or in some cases irreversibly anonymize it. When we delete
        any information, it will be deleted from our active databases but may
        remain in our archives.
      </P>
      <Header color='black'>
        <span className='item-number'>9.</span>
        Cookies
      </Header>
      <P>
        Our Services, online advertisements, and emails use first and
        third-party "cookies", pixel tags, and other technologies to
        automatically collect information about you such as browser type, pages
        viewed, links clicked, and other actions you take in connection with our
        Services, online advertisements, and email. A cookie is data sent to
        your Internet browser from a Web server and stored directly on your
        computer hard drive. Cookies and similar technologies within a web
        browser allow us to collect and store data as you navigate our website.
        We use this information for various purposes, including to facilitate
        web page navigation, display information more effectively, personalize
        your online experience, to understand how our websites are used by
        users, market our products and services, measure the success of our
        marketing campaigns, and for security purposes.
      </P>
      <P>
        Most web browsers are set up to accept cookies. You can adjust your
        browser settings to refuse all cookies or to inform you when a cookie is
        being placed on your hard drive. However, certain features of the
        Services may not work properly, and you may be required to re-enter
        certain information each time you use our website. For example, to use
        our shopping cart feature, you must have cookies enabled. For more
        information about cookies and how to disable them, please see our Cookie
        Policy.
      </P>
      <P>
        Where we use Google Analytics, we have set up the service to anonymize
        your IP address as soon as data is received by the Analytics Collection
        Network, before any storage or processing takes place. To opt out of
        being tracked by Google Analytics across all websites please visit{' '}
        <PrivacyLink
          href={'https://tools.google.com/dlpage/gaoptout'}
          target='_blank'
        >
          https://tools.google.com/dlpage/gaoptout
        </PrivacyLink>
        .
      </P>
      <Header color='black'>
        <span className='item-number'>10.</span>
        How We Protect Your Personal Information
      </Header>
      <P>
        Unfortunately, the transmission of information via the Internet or
        e-mail is not completely secure. Although we will do our best to protect
        personal information that you submit to us, we cannot guarantee the
        security of your data transmitted to our site; any transmission is at
        your own risk. To prevent unauthorized access, maintain data accuracy,
        and facilitate the correct use of personal information obtained through
        our website, we have put in place appropriate physical, technical, and
        operational measures to safeguard and secure the personal information we
        collect online against unauthorized access, unlawful use, accidental
        loss, corruption, or destruction.
      </P>
      <Header color='black'>
        <span className='item-number'>11.</span>
        Children
      </Header>
      <P>
        We do not knowingly contact or collect information from persons under
        the age of 16. The Services are not intended for use by anyone under the
        age of 16. If you are a parent or a legal guardian and think your child
        under 16 has provided us with personal information, you can email us at{' '}
        <MailtoLink mail='support@alomoves.com' />
      </P>
      <Header color='black'>
        <span className='item-number'>12.</span>
        Location of Your Personal Information / Your Particular Consent
      </Header>
      <P>
        We may disclose personal information to third-party service providers
        (e.g., value-added resellers, payment processors, data storage and
        processing facility vendors) that assist us in our work. We limit the
        personal information provided to these service providers to that which
        is reasonably necessary for them to perform their functions, and we
        require them to agree to maintain the confidentiality of such personal
        information
      </P>
      <P>
        <b>
          Consent to International Transfer and Disclosure of Personal
          Information:
        </b>{' '}
        Alo Moves is located in the United States (“U.S.”) and is subject to the
        applicable laws of the United States (where data privacy laws are less
        stringent than in the European Union and certain other jurisdictions).
        We will store and process information we receive about you, as described
        in this Privacy Policy, in the U.S.
      </P>
      <P>
        We are involved in business activities in a variety of countries. You
        agree that Alo Moves and those with whom we share your personal
        information (“Recipients”) may disclose, transfer, process, and store
        your personal information outside of your country of origin to our
        affiliates, sponsors and partners, and any other third-party service
        providers for the purposes described in this Privacy Policy.
      </P>
      <P>
        Some of the recipients of personal information as specified above may be
        located in countries that do not provide a level of data protection
        equivalent to that set forth by the European Union and some other
        jurisdictions. If you submit any personal information or choose to
        access or use the Services offered by Alo Moves such as our website, you
        consent to such disclosure, transfer, process, and storage of
        information in accordance with this Privacy Notice and subject to such
        applicable laws which may include access by law enforcement and other
        government entities including courts and tribunals. If we do transfer
        personal information outside the U.S., we will make sure that it is
        protected in the same way as if it were being used in the U.S. We will
        use one of the following legally recognized safeguards to protect your
        information:
      </P>
      <PrivacyUL>
        <P as='li'>
          Transfer the data to a non-EEA country that has privacy laws at least
          as protective as those within the EEA, or
        </P>
        <P as='li'>
          Put in place a contract with the recipient of the data, which means
          the recipient must protect the data to the same standards as required
          within the EEA.
        </P>
      </PrivacyUL>
      <Header color='black'>
        <span className='item-number'>13.</span>
        Your Opt-Out Choices
      </Header>
      <P>
        We also work with third parties such as Google and Facebook to manage
        our advertising on other websites. Our third-party advertising partners
        may use cookies or similar technologies on our website and other
        websites to provide you advertising based upon your browsing activities
        and interests, or to display ads that link back to our site. For more
        information about how targeted advertising works, you can visit the
        Network Advertising Initiative’s educational page. You can opt-out of
        some, but not all, online behavioral based advertising by clicking{' '}
        <PrivacyLink
          href='https://optout.networkadvertising.org/?c=1'
          target='_blank'
        >
          here
        </PrivacyLink>{' '}
        or by using your browser settings to block cookies or notify you when a
        cookie is set.
      </P>
      <P>
        If you do not want Alo Moves to send you email or postal mail regarding
        Alo Moves or its Services, you can choose not to provide your personal
        information to Alo Moves, even though it might be necessary to make a
        purchase or to take advantage of certain features on our Services. You
        may also manage, update and correct the information you provide as part
        of your online account by logging into your user account and clicking on
        the “Settings” hyperlink. If you choose not to give us certain personal
        information, you can still use many aspects of our website. However, you
        will not be able to access website areas that require account
        registration.
      </P>
      <P>
        If you have previously opted-in to receiving marketing emails from us,
        you can update your preferences to tell us specifically what you are
        interested in hearing about by clicking on the preferences link included
        in the marketing e-mails. You can update your preferences at any time.
      </P>
      <P>
        If you would prefer not to receive marketing emails sent by Alo Moves,
        simply click on the “unsubscribe” link included at the bottom of any of
        those emails. If you opt out of receiving marketing emails, we may still
        send you other types of messages, such as purchase receipts, information
        about shipments, or emails about your user account.
      </P>
      <Header color='black'>
        <span className='item-number'>14.</span>
        Your Rights Over Your Personal Information
      </Header>
      <P>
        <b>UK, EU, and Canadian Privacy Rights</b>
      </P>
      <P>
        If you are a resident in the United Kingdom, European Union, or Canada,
        you have a number of rights in relation to your personal information.
        Some of these rights apply generally, while others will only apply in
        certain circumstances or in certain jurisdictions. Further information
        and advice about your rights can be obtained by contacting us using the
        contact information provided below, or from the data protection
        regulator in your country.
      </P>
      <PrivacyUL>
        <P as='li'>Right to be informed.</P>
      </PrivacyUL>
      <P>
        You have the right to be provided with clear, transparent, and easily
        understandable information about how we use your information and your
        rights. This is why we’re providing you with the information in this
        Privacy Policy.
      </P>
      <PrivacyUL>
        <P as='li'>Right of access.</P>
      </PrivacyUL>
      <P>
        You can request access to your personal information (commonly known as a
        ‘data subject access request’). This enables you to receive a copy of
        the personal information we hold about you and to check that we are
        lawfully processing it.
      </P>
      <PrivacyUL>
        <P as='li'>Right to rectification.</P>
      </PrivacyUL>
      <P>
        This allows you to have your information corrected if it is inaccurate
        or incomplete.
      </P>
      <PrivacyUL>
        <P as='li'>Right to erasure.</P>
      </PrivacyUL>
      <P>
        This is also known as the ‘right to be forgotten’ and, depending on your
        jurisdiction, allows you to request the deletion or removal of your
        information where there is no compelling reason for us to keep using it.
        We may not always be able to comply with your request for specific legal
        reasons which will be notified to you, if applicable, at the time of
        your request.
      </P>
      <PrivacyUL>
        <P as='li'>Right to restrict processing.</P>
      </PrivacyUL>
      <P>
        Depending on your jurisdiction, you may have rights to ‘block’ or
        suppress further use of your information. When processing is restricted,
        we can still store your information, but may not use it further.
      </P>

      <PrivacyUL>
        <P as='li'>Right to data portability.</P>
      </PrivacyUL>
      <P>
        Depending on your jurisdiction, you may have rights to obtain, port and
        reuse your personal information for your own purposes across different
        services.
      </P>

      <PrivacyUL>
        <P as='li'>Right to object.</P>
      </PrivacyUL>
      <P>
        Depending on your jurisdiction, you can object to certain types of
        processing, including processing for direct marketing or where we are
        relying on our legitimate interests for processing.
      </P>

      <PrivacyUL>
        <P as='li'>Right to complain.</P>
      </PrivacyUL>
      <P>
        You have the right to make a complaint at any time to your local data
        protection supervisory authority. We would, however, appreciate the
        chance to respond to your concerns before you approach them so please
        consider contacting us in the first instance.
      </P>

      <PrivacyUL>
        <P as='li'>Right to withdraw consent.</P>
      </PrivacyUL>
      <P>
        If you have given your consent to anything we do with your personal
        information, you have the right to withdraw your consent at any time
        (although it does not mean that anything we have done with your personal
        information with your consent up to that point is unlawful). This
        includes your right to withdraw consent to us using your personal
        information for marketing and advertising purposes.
      </P>
      <P>
        If you want to exercise any of your rights, please contact Alo’s Chief
        Information Security Officer at <MailtoLink mail='legal@alomoves.com' />{' '}
        or write to us at ALO, LLC, ATTN: Legal, 9830 Wilshire Blvd, Beverly
        Hills, CA 90212.
      </P>
      <P>
        When you contact us, we will respond as soon as possible and where
        possible within one month. If your request is more complicated, it may
        take longer to respond to you, but we will respond within three months
        of your request. There is no charge for most requests, but if you ask us
        to provide a significant amount of data, for example, we may ask you to
        pay a reasonable administrative fee. To protect your privacy and
        security, we also may ask you to verify your identity before we provide
        any information to you.
      </P>

      <Header color='black'>
        <span className='item-number'>15.</span>
        Supplemental Notice for Nevada Residents
      </Header>
      <P>
        If you are a resident of Nevada, you have the right to opt-out of the
        sale of certain personal information to third parties who intend to
        license or sell that personal information. You can exercise this right
        by contacting us at <MailtoLink mail='legal@alomoves.com' /> with the
        subject line “Nevada Do Not Sell Request” and providing us with your
        name and the email address associated with your account. Please note
        that we do not currently sell your personal information as sales are
        defined in Nevada Revised Statutes Chapter 603A. If you have any
        questions, please contact us as set forth below
      </P>
      <Header color='black'>
        <span className='item-number'>16.</span>
        Your California Privacy Rights
      </Header>
      <P>
        The following section pertains to the rights of individuals or
        households in California (“California consumers”).
      </P>
      <P>
        <b>California "Shine The Light":</b> You have the right to ask us one
        time each year if we have disclosed personal information with third
        parties for direct marking purposes. If you would like to make such a
        request, please contact us using the information in the Contact Us
        section and indicate in your letter or message that it is a "Shine the
        Light request." However, note that at this time, we do not disclose
        personal information to third parties for direct marketing purposes.
      </P>
      <P>
        <b>Rights under the CCPA:</b> California residents have the right to
        make the following requests with respect to their personal information
        subject to the limitations and exceptions set forth in applicable law.
      </P>

      <PrivacyUL>
        <P as='li'>
          <b> Access.</b>
        </P>
      </PrivacyUL>
      <P>
        You can request that we disclose to you the following: (i) the
        categories of personal information that we have collected about you;
        (ii) the categories of sources from which we have collected personal
        information about you; (iii) the business or commercial purpose for
        collecting, sharing, or selling your personal information; (iv) the
        categories of personal information that we have sold or shared about you
        and the categories of third parties to whom the personal information was
        disclosed; (v) the categories of personal information that we have
        disclosed about you for our business purposes and the categories of
        vendors to whom the personal information was disclosed; and (vi) the
        specific pieces of personal information that we have collected about
        you. The categories of personal information we collect are generally
        described in this Privacy Policy but differ for individual consumers
        depending on the Services used by such consumers.
      </P>

      <PrivacyUL>
        <P as='li'>
          <b>Deletion.</b>
        </P>
      </PrivacyUL>
      <P>
        You can request that we delete the personal information that we
        collected from you, subject to certain exceptions.
      </P>
      <PrivacyUL>
        <P as='li'>
          <b>Correction.</b>
        </P>
      </PrivacyUL>
      <P>
        You can request that we correct inaccurate personal information that we
        maintain about you, subject to certain exceptions.
      </P>
      <PrivacyUL>
        <P as='li'>
          <b>Limit the Use/Disclosure of Sensitive Personal Information.</b>
        </P>
      </PrivacyUL>
      <P>
        You can request that we limit the use or disclosure of your sensitive
        personal information to those purposes set forth in applicable
        California law, subject to certain exceptions. We use and disclose
        sensitive personal information only for the purposes for which it was
        collected and we do not use it to infer characteristics about you.
      </P>

      <PrivacyUL>
        <P as='li'>
          <b>Do Not Sell or Share My Personal Information.</b>
        </P>
      </PrivacyUL>
      <P>
        You can request that we not sell or share your personal information to a
        third-party by clicking on the “Cookies” link at the bottom of the
        website. For purposes of this section, “sell” means the sale, rental,
        release, disclosure, dissemination, availability, transfer, or other
        oral, written, or electronic communication of your personal information
        to an outside party for monetary or other valuable consideration,
        subject to certain exceptions in applicable law. For purposes of this
        section, “share” means transferring, making available or otherwise
        disclosing your personal information to another party for purposes of
        sending advertising to you based on your personal information obtained
        from your activities across distinctly-branded sites. Note that if you
        would like to opt-out of cookie-based tracking for our behavioral
        advertising purposes, you will need to separately set your cookie
        preferences by clicking "DENY" on our cookie banner that may be in the
        footer of our site or broadcasting an opt-out preference signal
        recognized by us, such as a Global Privacy Control signal, on the
        browser or browser extensions that support such a signal. You can also
        visit www.aboutads.info/choices and https://optout.privacyrights.info to
        opt out of certain uses of cookies for advertising purposes. Your
        selections are specific to the device, website and browser you are
        using. Your selections are also deleted whenever you clear your cookies
        or your browser’s cache. We do not knowingly sell or share the personal
        information of minors under 16 years of age.
      </P>
      <PrivacyUL>
        <P as='li'>
          <b>Non-Discrimination.</b>
        </P>
      </PrivacyUL>
      <P>
        We will not discriminate against you for exercising any of your data
        privacy rights.
      </P>
      <PrivacyUL>
        <P as='li'>
          <b>Exercising Your Rights.</b>
        </P>
      </PrivacyUL>
      <P>
        To exercise your rights described above, please submit a request by
        either:
      </P>
      <PrivacyUL>
        <P className='circle' as='li'>
          Calling us at{' '}
          <PrivacyLink href='tel:+18553632200'>855-363-2200</PrivacyLink>.
        </P>
        <P className='circle' as='li'>
          Emailing us at <MailtoLink mail='legal@alomoves.com' />.
        </P>
      </PrivacyUL>
      <P>
        <b>Verifying Requests:</b> We will take reasonable steps to verify your
        identity prior to responding to certain of your requests. The
        verification steps will vary depending on the sensitivity of the
        personal information and whether you have an account with us.
      </P>
      <P>
        You may designate an authorized agent to make a request on your behalf.
        When submitting the request, please ensure the authorized agent is
        identified as an authorized agent. We will only use personal information
        provided in the request to verify the requestor's identity or authority
        to make it.
      </P>
      <P>
        Certain data protection laws limit the ability to make a request to
        twice within a 12-month period, and allow us to charge a reasonable fee
        for responding to numerous requests from the same user. We cannot
        respond to your request or provide you with personal information if we
        cannot verify your identity or authority to make the request and confirm
        the personal information relates to you.
      </P>
      <P>
        <b>Notice of Financial Incentive Program.</b> From time to time, we may
        collect personal information, such as email addresses and date of birth
        in connection with a promotion, offer or discount. Our offers and
        incentives generally reflect the value of the relationships that we have
        with the individuals who participate in these programs and the value of
        your personal information is reasonably related to the value of the
        offer or discount presented to you. Participation in these programs is
        voluntary and you may withdraw at any time by emailing us or following
        any specific instructions in applicable financial incentive program
        terms and conditions or by contacting us.
      </P>

      <Header color='black'>
        <span className='item-number'>17.</span>
        Your Colorado, Connecticut, Delaware, Iowa, Montana, Nebraska, New
        Hampshire, New Jersey, Oregon, Texas, Utah, And Virginia Privacy Rights
      </Header>
      <P>
        The following section pertains to the rights of individuals residing in
        Colorado, Connecticut, Delaware, Iowa, Montana, Nebraska, New Hampshire,
        New Jersey, Oregon, Texas, Utah, and Virginia in accordance with
        applicable law and subject to the exceptions set forth in such
        applicable laws:
      </P>
      <PrivacyUL>
        <P as='li'>
          <b>Access.</b>
        </P>
      </PrivacyUL>
      <P>
        You can request to confirm whether we process your personal information.
        Subject to certain exceptions, you can also request a copy of your
        personal information. Oregon residents may request a list of specific
        third parties to whom we have disclosed personal information.
      </P>

      <PrivacyUL>
        <P as='li'>
          <b>Deletion.</b>
        </P>
      </PrivacyUL>
      <P>
        You can request that we delete personal information we have from or
        about you, subject to certain exceptions.
      </P>

      <PrivacyUL>
        <P as='li'>
          <b>Correction.</b>
        </P>
      </PrivacyUL>
      <P>
        You can request that we correct inaccurate personal information we
        maintain about you, subject to certain exceptions.
      </P>

      <PrivacyUL>
        <P as='li'>
          <b>Opt-Out of Sale.</b>
        </P>
      </PrivacyUL>
      <P>
        We may “sell” personal information about Colorado and Connecticut
        residents as the term is defined under applicable state laws. You can
        request to opt out of the sale of your personal information to a
        third-party.
      </P>

      <PrivacyUL>
        <P as='li'>
          <b>Opt-Out of Targeted Advertising.</b>
        </P>
      </PrivacyUL>
      <P>
        We may use personal information obtained (or in some instances inferred)
        from your activities across unaffiliated sites to send more relevant
        advertising to you. You can request that we stop using your personal
        information for such targeted advertising.
      </P>
      <P>
        <b>Submitting Requests:</b> A Colorado, Connecticut, Delaware, Iowa,
        Montana, New Hampshire, New Jersey, Oregon, Texas, Utah, and Virginia
        resident can exercise these rights by:
      </P>
      <PrivacyUL>
        <P className='circle' as='li'>
          Calling us at{' '}
          <PrivacyLink href='tel:+18553632200'>855-363-2200</PrivacyLink>.{' '}
        </P>
        <P className='circle' as='li'>
          Emailing us at <MailtoLink mail='legal@alomoves.com' />.
        </P>
      </PrivacyUL>
      <P>
        Note that for purposes of these rights under this section, personal
        information does not include information about job applicants, employees
        and other of our personnel or information about employees and other
        representatives of third-party entities we may interact with.
      </P>
      <P>
        <b>Sale:</b> We may “sell” (as such term is defined in the applicable
        state law) and process for targeted advertising purposes the following
        categories of personal information, including for crosscontext
        behavioral advertising: identifiers and contact information; personal
        records; personal characteristics or traits; commercial information;
        internet and other electronic network usage information; geolocation
        information; audiovisual and similar information; and inferences. We may
        “sell” or share these categories of personal information to or with the
        categories of third parties described in the section of this Privacy
        Policy titled “How We Share Your Personal Information.” Please note that
        if you would like to opt out of sales or our targeted advertising
        through certain cookie-based tracking for advertising purposes, you will
        need to separately set your cookie preferences by clicking on the
        “Cookie” link that may be in the footer of our site or broadcasting an
        opt-out preference signal recognized by us, such as a Global Privacy
        Control signal, on the browser or browser extensions that support such a
        signal. Please keep in mind that your settings are specific to the Site
        you were on when you set your preferences. You can also visit
        www.aboutads.info/choices and https://optout.privacyrights.info to opt
        out of certain uses of cookies for advertising purposes. Your
        cookie-related selections are specific to the device, website, and
        browser you are using. Your selections are deleted whenever you clear
        your cookies or browser’s cache.
      </P>
      <P>
        <b>Verifying Requests:</b> We will take reasonable steps to verify your
        identity prior to responding to your requests. The verification steps
        will vary depending on the sensitivity of the personal information and
        whether you have an account with us. We may deny certain requests and
        will explain why your request was denied and how to appeal our decision,
        as required by applicable law. After receiving our explanation, if you
        would like to appeal our decision, you may do so as further detailed in
        our communication back to you. For questions, you may also contact us by
        calling or emailing us at the contact information above.
      </P>
      <P>
        If you are a resident of Colorado, Connecticut, Delaware, Iowa, Montana,
        Nebraska, New Hampshire, New Jersey, Oregon, or Texas you may designate
        an agent to submit an opt-out of sale and opt-out of targeted
        advertising request on your behalf. Proof to verify your identity and
        the authorized agent’s authority to submit requests on your behalf is
        required, or we may deny the request. We use commercially reasonable
        efforts to authenticate your identity and the authorized agent’s
        authority through the written documentation provided in connection with
        the opt-out request in accordance with this Privacy Policy.
      </P>
      <P>
        We may deny certain requests or fulfil a request only in part, based on
        our legal rights and obligations. We will explain why your request was
        denied and how to appeal our decision, as required by applicable law. If
        you are a resident of Colorado, Connecticut, Delaware, Iowa, Montana,
        Nebraska, New Hampshire, New Jersey, Oregon, Texas, or Virginia and are
        not satisfied with the outcome of your request, you may submit an appeal
        by sending an email to <MailtoLink mail='legal@alomoves.com' /> with
        your state of residence and “Appeal” in the subject line. For questions,
        you may also contact us using the contact information in the Contact Us
        section below.
      </P>
      <P>
        From time to time, we or our vendors on our behalf may collect personal
        information in connection with a promotion, offer, program, discount or
        other benefits. We or our vendors may disclose such personal information
        to the categories of third parties described in the section of this
        Privacy Policy titled “How We Share Your Personal Information.”
      </P>
      <Header color='black'>
        <span className='item-number'>18.</span>
        Complaints
      </Header>
      <P>
        If you have any complaints concerning Alo Moves’ processing of your
        personal information, please contact us using the information in the
        Contact Us section.
      </P>
      <P>
        Please note that if you are an EU Resident, you may have the right to
        lodge a complaint with a European Union supervisory authority that is
        responsible for the protection of personal information in the country
        where you live or work, or in which you think a breach of data
        protection laws might have taken place. You can learn more about these
        rights at{' '}
        <PrivacyLink
          target='_blank'
          href='https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en'
        >
          https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en
        </PrivacyLink>
        .
      </P>
      <P>
        Customers in the United Kingdom can contact the Information
        Commissioner’s Office at telephone on 0303 123 1113, or by using the
        live chat service that is available through the Information
        Commissioner’s website{' '}
        <PrivacyLink target='_blank' href='https://ico.org.uk/'>
          www.ico.org.uk
        </PrivacyLink>
        .
      </P>
      <Header color='black'>
        <span className='item-number'>19.</span>
        Contact Us
      </Header>
      <P>
        If you'd like more information about our privacy practices or want us to
        delete your personal information, feel free to contact us at{' '}
        <MailtoLink mail='legal@alomoves.com' />
      </P>
      <P fontWeight='600'>
        Alo Moves
        <br></br>
        Attn: Legal
        <br></br>
        9830 Wilshire Blvd
        <br></br>
        Beverly Hills, CA 90212
      </P>
      <Header color='black'>
        <span className='item-number'>20.</span>
        Changes to This Privacy Policy
      </Header>
      <P>
        Alo Moves may change this Privacy Policy from time to time to reflect
        our current practices. Any changes to this Privacy Policy will be posted
        on this website and will be effective when posted unless another date is
        referenced in the notice. We recommend you check the Privacy Policy on a
        regular basis so that you know the current terms and conditions that
        apply to you. If you use our Services after any changes are effective,
        you are agreeing to comply with and be bound by them.
      </P>
    </PrivacyContainer>
  )
}

export default PrivacyText
