import styled from 'styled-components'
import { P as UnstyledP, Header as UnstyledHeader } from 'ui/acro/typography'
import React from 'react'

const P = styled(UnstyledP)`
  margin-bottom: 15px;
`

const Header = styled(UnstyledHeader)`
  padding-top: 20px;
  padding-bottom: 10px;
`

const PrivacyOL = styled('ol')`
  padding: 0px 0px 0px 10px !important;
`

const PrivacyUL = styled('ul')`
  margin-block-start: 5px !important;
  padding-inline-start: 10px !important;
  list-style-type: disc !important;
`

const PrivacyLink = styled('a')`
  text-decoration: underline;
`
const MailLink = styled(PrivacyLink)`
  color: #0052cc;
`

const MailtoLink = ({ mail = '' }) => (
  <MailLink href={`mailto:${mail}`}>{`${mail}`}</MailLink>
)

const PrivacyContainer = styled.div`
  .item-number {
    font-weight: 400;
    margin-right: calc(min(35px, 1.5vw));
  }
  h3 {
    margin-bottom: 10px;
    display: flex;
    font-weight: 700;
  }
  ol,
  ul {
    margin-top: 30px;
    margin-left: 3vw;
  }
  .circle {
    margin-left: 5vw;
    list-style-type: circle;
  }
`

Header.defaultProps = {
  variant: 'semibold'
}

export {
  P,
  Header,
  PrivacyOL,
  PrivacyUL,
  PrivacyLink,
  PrivacyContainer,
  MailtoLink
}
